import React, { ReactElement, useState } from "react"
import BackToPageButton from "components/BackToPageButton"
import { useMutation, useQuery } from "@apollo/client"
import { withApollo } from "hocs/withApollo"
import { GetPayorUsers } from "../graphql/usersQuery"
import { UsersTable } from "./components/DataGrid/UsersTable"
import {
  User,
  UserConnection,
  EmploymentRolesEnum,
} from "graphql/__generated__/graphql"
import { materialTheme, MaterialThemeProvider } from "../../../../themes/theme"
import ParachuteMuiDataGridContainer from "../../../../components/ParachuteMuiDataGrid/ParachuteMuiDataGridContainer"
import { MutationStatus, UserFormAction } from "../components/UserForm"
import Alert from "../../../../components/Alert"
import { usersTableColumnDefinitions } from "./components/DataGrid/tableColumnDefinitions"
import { UserActionDrawer } from "./components/DataGrid/UserActionDrawer"
import { alpha, makeStyles } from "@material-ui/core/styles"
import { GridRowParams } from "@mui/x-data-grid-pro"
import { PayorUserUpdate } from "../graphql/userUpdateMutation"
import { sendPasswordResetInstructions } from "../../../ForgotPassword/api"
import { PayorUserCreate } from "../graphql/userCreateMutation"
import { RemoveUserDialogModal } from "./components/RemoveUserDialogModal"
import { PayorEmploymentDelete } from "../graphql/userDeleteMutation"
import { sendWelcomeEmail } from "applications/ManageUsers/components/Actions/api"
import { SearchNavbar } from "./components/SearchNavbar/SearchNavbar"

export const PAGE_SIZE = 25
const theme = materialTheme

const useStyles = makeStyles({
  rowEditSelect: {
    backgroundColor: `${alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
    )} !important`,
  },
  gridWithActionDrawer: {
    width: "calc(100% - min(600px, 40%) + 5%)",
  },
  actionDrawer: {
    width: "min(600px, 40%)",
  },
})

const ACTION_SUCCESS_MESSAGE = "Changes have been saved."
const ACTION_ERROR_MESSAGE =
  "We are unable to save at this time. Please try again in a few minutes."

const UsersListPage = (): ReactElement => {
  const muiStyleClasses = useStyles()
  const [selectedEditUser, setSelectedEditUser] = useState<User | null>(null)
  const [selectedRemoveUser, setSelectedRemoveUser] = useState<User | null>(
    null
  )
  const [pageAlert, setPageAlert] = useState<MutationStatus | null>(null)
  const [
    currentUserFormAction,
    setCurrentUserFormAction,
  ] = useState<UserFormAction | null>(null)

  const { data, loading, fetchMore, refetch } = useQuery(GetPayorUsers, {
    variables: { first: PAGE_SIZE },
  })
  const usersData = data?.users as UserConnection

  const [userCreate, { loading: userCreateLoading }] = useMutation(
    PayorUserCreate,
    {
      onCompleted: (result) => {
        setPageAlert({
          status: "success",
          message: result.userCreate?.user
            ? `${result.userCreate.user.firstName} ${result.userCreate?.user.lastName} has been added.`
            : ACTION_SUCCESS_MESSAGE,
        })
        handleCloseActionDrawer()
      },
      onError: (e) => {
        console.log({ error: e })
        setPageAlert({
          status: "error",
          message: ACTION_ERROR_MESSAGE,
        })
      },
      refetchQueries: [GetPayorUsers],
    }
  )

  const [userUpdate, { loading: userUpdateLoading }] = useMutation(
    PayorUserUpdate,
    {
      onCompleted: (result) => {
        setPageAlert({
          status: "success",
          message: result.userUpdate?.user
            ? `Changes to ${result.userUpdate.user.firstName} ${result.userUpdate?.user.lastName} have been saved.`
            : ACTION_SUCCESS_MESSAGE,
        })
        handleCloseActionDrawer()
      },
      onError: () =>
        setPageAlert({
          status: "error",
          message: ACTION_ERROR_MESSAGE,
        }),
      refetchQueries: [GetPayorUsers],
    }
  )

  const [employmentDelete, { loading: employmentDeleteLoading }] = useMutation(
    PayorEmploymentDelete,
    {
      onCompleted: () => {
        setPageAlert({
          status: "success",
          message: `${selectedRemoveUser?.firstName} ${selectedRemoveUser?.lastName} has been removed.`,
        })
        setSelectedRemoveUser(null)
      },
      onError: () => {
        setPageAlert({
          status: "error",
          message: "Unable to remove user. Please try again in a few minutes.",
        })
        setSelectedRemoveUser(null)
      },
      refetchQueries: [GetPayorUsers],
    }
  )

  const handleEditActionClick = (user: User) => {
    setCurrentUserFormAction(UserFormAction.Edit)
    setSelectedEditUser(user)
  }

  const handleWelcomeEmailActionClick = async (user: User) => {
    try {
      await sendWelcomeEmail(user.id)

      setPageAlert({
        status: "success",
        message: `The welcome email has been sent to ${user.email}`,
      })
    } catch (e) {
      setPageAlert({
        status: "error",
        message:
          "Unable to send welcome email. Please try again in a few minutes.",
      })
    }
  }

  const handlePasswordResetActionClick = async (user: User) => {
    try {
      await sendPasswordResetInstructions({ email: user.email })
      setPageAlert({
        status: "success",
        message: `A password reset email has been sent to ${user.email}`,
      })
    } catch (e) {
      setPageAlert({
        status: "error",
        message:
          "Unable to send password reset email. Please try again in a few minutes.",
      })
    }
  }

  const handleCloseActionDrawer = () => {
    setSelectedEditUser(null)
    setCurrentUserFormAction(null)
  }

  const handleFormSubmit = (values) => {
    const mutationVariables = {
      ...values,
    }

    if (currentUserFormAction === UserFormAction.Edit) {
      mutationVariables["userId"] = selectedEditUser?.id
      userUpdate({ variables: mutationVariables })
    } else if (currentUserFormAction === UserFormAction.New) {
      userCreate({ variables: mutationVariables })
    }
  }

  const handleEmploymentDelete = () => {
    if (selectedRemoveUser?.currentEmployment) {
      employmentDelete({
        variables: { id: selectedRemoveUser.currentEmployment.id },
      })
    }
  }

  return (
    <div
      className="canopy-mx-8x"
      style={{ minWidth: "fit-content", maxHeight: "fit-content" }}
    >
      {selectedRemoveUser && (
        <RemoveUserDialogModal
          loading={employmentDeleteLoading}
          onCancel={() => setSelectedRemoveUser(null)}
          onRemove={handleEmploymentDelete}
          user={selectedRemoveUser}
        />
      )}
      {pageAlert && (
        <Alert
          rightIcon="fa-times"
          status={pageAlert.status}
          bordered
          onRightIconClick={() => setPageAlert(null)}
        >
          {pageAlert.message}
        </Alert>
      )}
      <div className="canopy-mbs-4x canopy-mbe-8x">
        <BackToPageButton path="." labelText="Back to Member Search" />
      </div>
      <h1 className="canopy-typography-heading-2xlarge canopy-mbe-16x">
        Users
      </h1>
      <MaterialThemeProvider>
        <ParachuteMuiDataGridContainer>
          <SearchNavbar
            searchFetchCallback={async (searchString) =>
              await refetch({ searchString })
            }
            addUserCallback={() => setCurrentUserFormAction(UserFormAction.New)}
          />

          <UsersTable
            loading={loading}
            usersData={usersData}
            refetchData={refetch}
            paginate={fetchMore}
            pageSize={PAGE_SIZE}
            columnDefs={usersTableColumnDefinitions({
              editCallback: (row) => handleEditActionClick(row as User),
              welcomeEmailCallback: (row) =>
                handleWelcomeEmailActionClick(row as User),
              passwordResetCallback: (row) =>
                handlePasswordResetActionClick(row as User),
              removeCallback: (row) => setSelectedRemoveUser(row as User),
            })}
            getRowClassName={(params: GridRowParams) => {
              return params.id === selectedEditUser?.id
                ? muiStyleClasses.rowEditSelect
                : ""
            }}
            className={
              selectedEditUser ? muiStyleClasses.gridWithActionDrawer : ""
            }
          />
          {!!currentUserFormAction && (
            <UserActionDrawer
              open={!!currentUserFormAction}
              onClose={handleCloseActionDrawer}
              paperClasses={muiStyleClasses.actionDrawer}
              handleFormSubmit={handleFormSubmit}
              loading={userUpdateLoading || userCreateLoading}
              initialFormValues={{
                firstName: selectedEditUser?.firstName || "",
                lastName: selectedEditUser?.lastName || "",
                email: selectedEditUser?.email || "",
                role:
                  selectedEditUser?.currentEmployment?.role ||
                  EmploymentRolesEnum.ViewOnly,
              }}
              formAction={currentUserFormAction}
            />
          )}
        </ParachuteMuiDataGridContainer>
      </MaterialThemeProvider>
    </div>
  )
}

export default withApollo(UsersListPage)
